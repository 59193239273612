import React from 'react'
import {Deutsch, English, Type, LinkABOUT, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img0 from "../../images/inventify/0.png"

import img1 from "../../images/inventify/1.png"
import img2 from "../../images/inventify/2.png"
import img3 from "../../images/inventify/3.png"
import img4 from "../../images/inventify/4.png"

import img5 from "../../images/inventify/5.png"
import img6 from "../../images/inventify/6.png"
import img7 from "../../images/inventify/7.png"



// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;






export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    inventify is a Swiss software company that builds next-generation no-code platforms.
                    <p/>
                    I created the wireframes and wrote their website text in English and German. <a href="http://www.distylerie.com/" target="_blank">distylerie</a> created the branding.
                    <p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>

        <Deutsch Language={location.search}>
              <Type>
                    inventify ist eine Schweizer Software-Firma, welche No-Code Plattformen der nächsten Generatoin baut.
                    <p/>
                    Ich konzipierte die Wireframes und schrieb den Webseitentext auf Englisch und Deutsch. <a href="http://www.distylerie.com/" target="_blank">distylerie</a> machte das Branding.
                  <p/>
                  <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>
              </Type>
        </Deutsch>


    </div>

    <Element name="test1"></Element>




    <img src={img0}/>
    <img src={img1}/>
    <img src={img2}/>
    <img src={img3}/>
    <img src={img4}/>
    <img src={img5}/>
    <img src={img6}/>
    <img src={img7}/>






      <Back_To_All_Projects location={location} />



      </Page>
    </Layout>

)
